import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import ReactGA from "react-ga"

import Layout from "../../components/layout"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Link = ({ pageContext }) => {
  const { link, campaign } = pageContext

  useEffect(() => {
    ReactGA.event({
      category: "QR Code",
      action: campaign.title,
      label: `${link.key} - ${link.url}`,
      value: 1,
    })
  }, [])

  return (
    <>
      <Helmet>
        <meta http-equiv="refresh" content={`0; url=${link.url}`} />
      </Helmet>
      <Layout>
        <p>Redirecting to:</p>
        <p>
          <a href={link.url}>{link.url}</a>
        </p>
      </Layout>
    </>
  )
}
export default Link
